import { AppBar as MUAppBar, Box, IconButton, Toolbar, Typography, Menu, MenuItem } from "@mui/material";
import AccountCircle from '@mui/icons-material/AccountCircle';
import * as Sentry from '@sentry/react';
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthDispatch, useAuthState } from "../contexts/auth/context";
import { fetchApi } from "../core/fetchApi";

const useStyles = () => ({
  title: {
    flexGrow: 1,
  },
  appbar: {
    borderBottom: '2px solid #C5382C',
    backgroundImage: 'linear-gradient(to right,white,#175985,#175985)',
    color: 'black'
  }
});

type Props = {
  title?: string;
}

const AppBar = (props: Props) => {
  const styles = useStyles();
  const { email } = useAuthState();
  const { signOut } = useAuthDispatch();

  // For NavBar
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOutAllOthers = () => {
    fetchApi({ url: 'RevokeAllOthers', payload: {}, method: 'post' })
      .then(() => {
        alert('All other devices have been signed out');
        handleClose();
      })
      .catch(() => {
        alert('An error occurred signing out all other devices');
      });
  }

  const emailSupport = () => {

    // Create Sentry Issue
    Sentry.withScope((scope) => {
      scope.setLevel('debug');
  
      const subject = `My Freight Support Request for ${email}`;
      const sentryId = Sentry.captureMessage(subject);
      const sentryUrl = `https://sentry.io/issues/?project=4507255208148992&query=id%3A${sentryId}`;
      const body = `Please attach any screen shots if possible and indicate any and all identifiers below that apply to this issue that would help us determine the cause:
  
Quote No: 
Order No: 
Other Identifier: 
Nature of Problem: 
When did problem start occurring: 
Other Relevant Info: 

--------------------

The link below is used to assist tech. support with your issue.  Please do not remove it from this email.
${sentryUrl}`;
      window.location.href = `mailto:app@load1.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    });
  }

  return (
    <MUAppBar position="static" style={styles.appbar}>
      <Toolbar>
        <Box style={{ paddingRight: 10 }}>
          <Link to="/">
            <img
              height="32"
              src={`/images/logo.png`}
              alt='Load One Logo'
            />
          </Link>
        </Box>
        <Typography variant="h6" style={styles.title}>
          {props.title || "My Freight"}
        </Typography>
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            size="large">
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem disabled>{email}</MenuItem>
            <MenuItem component={Link} to="/notifications">Email Notifications</MenuItem>
            <MenuItem onClick={emailSupport}>Email Support</MenuItem>
            <MenuItem onClick={signOutAllOthers}>Sign Out All Other Devices</MenuItem>
            <MenuItem onClick={signOut}>Sign Out</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </MUAppBar>
  );
}

export default AppBar;